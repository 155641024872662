import * as React from 'react';
import { Component } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import Crag from '../models/Crag';
import './CragModal.css';
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import climbingBouldering from './assets/boulder.png';
import climbingSport from './assets/quickdraw_double.png';
import climbingTrad from './assets/trad_cam.png';
import climbingIce from './assets/ice_axe.png';
import { FavoriteCragsStorage } from '../utils/FavoriteCragsStorage';

export interface CragModalProps {
    toggle: () => void,
    cragId: number,
    cragName: string,
    storage: FavoriteCragsStorage
};

interface CragModalState {
    crag: Crag | null,
    isFavorite: boolean
};

export default class CragModal extends Component<CragModalProps, CragModalState> {
    private _isMounted = false;

    constructor(props: CragModalProps) {
        super(props);
        this.state = { crag: null, isFavorite: false };
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchCrag(this.props.cragId);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async fetchCrag(id: number) {
        const response = await fetch(`https://api.cragtime.com/api/v1/crags/${id}`);
        const data: Crag = await response.json();
        const newState: CragModalState = { crag: data, isFavorite: this.props.storage.cragExists(data) };

        if (this._isMounted) {
            this.setState(newState);
        }
    }

    async setFavorite(id: number) {
        const url = `https://api.cragtime.com/api/v1/crags/${id}`;
        const options: RequestInit = {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(this.state.crag)
        };

        let response: Response | null = null;
        try {
            response = await fetch(url, options);
        }
        catch (ex) {
            console.log(ex);
        }

        if (response !== null && response!.status !== 204) {
            console.log(`ERROR: API call failed! {${url}}`);
        }
    }

    handleKeyDownFavorite(e: React.KeyboardEvent) {
        if (e.key !== " " && e.key !== "Enter") return;

        if (this.state.crag !== null) {
            this.toggleFavorite(this.state.crag, this.state.isFavorite);
        }
    }

    handleClickFavorite() {
        if (this.state.crag !== null) {
            this.toggleFavorite(this.state.crag, this.state.isFavorite);
        }
    }

    toggleFavorite(crag: Crag, isFavorite: boolean) {
        if (this._isMounted) {
            isFavorite = !isFavorite;

            if (isFavorite) {
                this.props.storage.addCrag(crag);
            }
            else {
                this.props.storage.removeCrag(crag);
            }

            this.setState({ crag: crag, isFavorite: isFavorite });
        }
    }

    render() {
        const body =
            this.state.crag === null ?
                <Alert variant="light" className="message">Loading...</Alert> :
                <>
                    <div className="crag-info">
                        <div className="crag-info-favicon"
                            tabIndex={0}
                            onClick={this.handleClickFavorite.bind(this)}
                            onKeyDown={this.handleKeyDownFavorite.bind(this)}>
                            {this.state.isFavorite ? <MdFavorite className="favorite" /> : <MdFavoriteBorder />}
                        </div>
                        <div className="crag-info-name">{this.props.cragName}</div>
                        <div className="crag-info-areaname">{this.state.crag.areaName !== undefined ? this.state.crag.areaName : `\u00a0`}</div>
                        <span className="clearfix"></span>
                        <div className="crag-info-status crag-info-status-bad">Mixed Reviews</div>
                    </div>
                    <hr />
                    <div className="crag-location">
                        <div className="crag-location-text">
                            {this.state.crag.locationAddressLine1 !== undefined ? <div>{this.state.crag.locationAddressLine1}</div> : null}
                            <div>{this.state.crag.locationCity}</div>
                            <div>{`${this.state.crag.locationRegion} (${this.state.crag.locationCountry})`}</div>
                        </div>
                        <a className="crag-location-map" href={`https://www.google.com/maps/place/${this.state.crag.locationLatitude},${this.state.crag.locationLongitude}`}>View Map</a>
                    </div>
                    <hr />
                    <div className="crag-climb-types">
                        {this.state.crag.hasBouldering ? <img className="type-bouldering" src={climbingBouldering} alt="Crag has bouldering." title="Crag has bouldering." height="40" width="40" /> : null}
                        {this.state.crag.hasSportClimbing ? <img className="type-sport" src={climbingSport} alt="Crag has sport climbing." title="Crag has sport climbing." height="40" width="40" /> : null}
                        {this.state.crag.hasTradClimbing ? <img className="type-trad" src={climbingTrad} alt="Crag has trad climbing." title="Crag has trad climbing." height="40" width="40" /> : null}
                        {this.state.crag.hasIceClimbing ? <img className="type-ice" src={climbingIce} alt="Crag has ice climbing." title="Crag has ice climbing." height="40" width="40" /> : null}
                    </div>
                </>;

        return (
            <Modal centered keyboard show aria-labelledby="crag-modal" onHide={this.props.toggle}>
                <Modal.Header closeButton>
                    <Modal.Title id="crag-modal">{this.props.cragName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.toggle}>Action</Button>
                    <Button variant="primary" onClick={this.props.toggle}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
};