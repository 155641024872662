import React, { Component } from 'react';
import { Nav, Container, Tab, Alert } from 'react-bootstrap';
import Crag from '../models/Crag';
import './ClimbOrNot.css';
import CragModal from './CragModal';
import { FavoriteCragsStorage } from '../utils/FavoriteCragsStorage';

export interface ClimbOrNotProps {
};

interface ClimbOrNotState {
    crags: Crag[],
    loading: boolean,
    selectedCrag: Crag | null | undefined
};

export class ClimbOrNot extends Component<ClimbOrNotProps, ClimbOrNotState> {
    private _isMounted = false;
    static displayName = ClimbOrNot.name;

    constructor(props: ClimbOrNotProps) {
        super(props);
        this.state = { crags: [], loading: true, selectedCrag: null };
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchCrags();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async fetchCrags() {
        //TODO: URL should be in settings.
        const response = await fetch('https://api.cragtime.com/api/v1/Crags');
        const data = await response.json();

        if (this._isMounted) {
            this.setState({ crags: data, loading: false, selectedCrag: null });
        }
    }

    handleClick(crag: Crag) {
        this.setState({ selectedCrag: crag });
    }

    handleKeyDown(crag: Crag, e: React.KeyboardEvent) {
        if (e.key !== " " && e.key !== "Enter") return;

        this.setState({ selectedCrag: crag });
    }

    hideCragModal() {
        this.setState({ selectedCrag: null });
    }

    renderTabs() {
        const storage = new FavoriteCragsStorage();
        const favoriteCragsContent =
            this.state.loading ?
                <Alert variant="light" className="message">Loading...</Alert> :
                storage.favoriteCrags.length === 0 ?
                    <Alert variant="primary" className="message-no-favorites">No favorite crags to display...</Alert> :
                    this.renderCrags(storage.favoriteCrags);
        const allCragsContent =
            this.state.loading ?
                <Alert variant="light" className="message">Loading...</Alert> :
                this.state.crags.length === 0 ?
                    <Alert variant="primary" className="message">No crags to display...</Alert> :
                    this.renderCrags(this.state.crags);

        const content =
            <Tab.Container id="tabs-crags" defaultActiveKey="favorites">
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="favorites">
                            Favorites
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="all">
                            All Crags
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="favorites">
                        {favoriteCragsContent}
                    </Tab.Pane>
                    <Tab.Pane eventKey="all">
                        {allCragsContent}
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>;

        return content;
    }

    renderCrags(crags: Crag[]) {
        const content =
            <Container className="crags">
                {crags.map((item: Crag) => (
                    <div className="crag-item clearfix" tabIndex={0} key={item.cragId} onClick={this.handleClick.bind(this, item)} onKeyDown={this.handleKeyDown.bind(this, item)}>
                        <div className="crag-item-thumbnail">
                            <img src="https://via.placeholder.com/50" alt={`${item.name} - Thumbnail`} />
                        </div>
                        <div className="crag-item-name">
                            {item.name}
                        </div>
                        <div className="crag-item-location">
                            {`${item.locationRegion} (${item.locationCountry})`}
                        </div>
                    </div>
                ))}
            </Container>;

        return content;
    }

    render() {
        const contents = this.renderTabs();

        const modal =
            this.state.selectedCrag !== null ?
                <CragModal toggle={this.hideCragModal.bind(this)}
                    cragId={this.state.selectedCrag!.cragId}
                    cragName={this.state.selectedCrag!.name}
                    storage={new FavoriteCragsStorage()} /> : null;

        return (
            <div>
                <h1>CLIMB-or-NOT</h1>
                <p>Helping rock climbers monitor and report on various conditions that can ruin a trip to their favorite or nearest crags.</p>
                {contents}
                {modal}
            </div>
        );
    }
};