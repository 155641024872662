import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './NavMenu.css';

export interface NavMenuProps {
};

interface NavMenuState {
};

export class NavMenu extends Component<NavMenuProps, NavMenuState> {
    static displayName = NavMenu.name;

    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <Navbar bg="light" expand="lg" className="border-bottom box-shadow mb-3">
                <Container>
                    <Navbar.Brand href="/">CragTime</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Item>
                                <Nav.Link as={Link} to="/">Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/climb-or-not">CLIMB-or-NOT</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}
