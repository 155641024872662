// Forked from: https://gist.githubusercontent.com/Digiman/9fc2640b84bbe5162cf1/raw/93b413355c3a32596912c3f711c5c856d7dd8345/StorageHelper.ts
import { LocalStorageWorker } from "./StorageHelper";
import Crag from '../models/Crag';

// custom class for store favorite crags in local storage
export class FavoriteCragsStorage {
    storageWorker: LocalStorageWorker;

    // main key that use for store list of favorite crags
    storageKey: string;

    // list of favorite crags.
    favoriteCrags: Array<Crag>;

    constructor(storageKey: string = `${FavoriteCragsStorage.name}`) {
        this.storageWorker = new LocalStorageWorker();

        this.storageKey = storageKey;

        this.favoriteCrags = new Array<Crag>();

        this.activate();
    }

    // activate custom storage for favorite crags
    activate() {
        //this.clear();

        this.loadAll();
    }

    // load all favorite crags from storage to list for working with it
    loadAll() {
        var storageData = this.storageWorker.get(this.storageKey);

        if (storageData != null && storageData.length > 0) {
            var crags: Array<Crag> = JSON.parse(storageData);
            if (crags != null) {
                this.favoriteCrags = crags;
                this.favoriteCrags.sort((x, y) => x.name.localeCompare(y.name));
            }
        }
    }

    cragExists(crag: Crag): boolean {
        const exists = this.favoriteCrags.some((value) => {
            return (value.cragId === crag.cragId);
        });

        return exists;
    }

    // add new favorite crags (without duplicate)
    addCrag(crag: Crag) {
        // don't create duplicates in favorite crags.
        if (!this.cragExists(crag)) {
            this.favoriteCrags.push(crag);
            this.favoriteCrags.sort((x, y) => x.name.localeCompare(y.name));
        }

        // save to storage
        this.save();
    }

    // remove favorite crags
    removeCrag(crag: Crag) {
        const index = this.favoriteCrags.findIndex((value) => {
            return (value.cragId === crag.cragId);
        });
        if (index > -1) {
            this.favoriteCrags.splice(index, 1);
        }

        // save to storage
        this.save();
    }

    // clear all data about favorite crags
    clear() {
        // remove data by key from storage
        this.storageWorker.add(this.storageKey, "");

        // or remove with key
        //this.storageWorker.remove(this.storageKey);
    }

    // save to storage (save as JSON string)
    save() {
        var json = JSON.stringify(this.favoriteCrags);
        this.storageWorker.add(this.storageKey, json);
    }
}